<template>
  <div class="rounded-xl shadow-xl bg-white p-6">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">用户管理</h1>
        <p class="mt-2 text-sm text-gray-700">
          管理有用户的信息，包括他们的名字，头衔， 电子邮件和角色。
        </p>
      </div>
      <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <!--        <button-->
        <!--          type="button"-->
        <!--          class="-->
        <!--            inline-flex-->
        <!--            items-center-->
        <!--            justify-center-->
        <!--            rounded-md-->
        <!--            border border-transparent-->
        <!--            bg-indigo-600-->
        <!--            px-4-->
        <!--            py-2-->
        <!--            text-sm-->
        <!--            font-medium-->
        <!--            text-white-->
        <!--            shadow-sm-->
        <!--            hover:bg-indigo-700-->
        <!--            focus:outline-none-->
        <!--            focus:ring-2-->
        <!--            focus:ring-indigo-500-->
        <!--            focus:ring-offset-2-->
        <!--            sm:w-auto-->
        <!--          "-->
        <!--        >-->
        <!--          添加用户-->
        <!--        </button>-->
      </div>
    </div>
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div
            class="
              relative
              overflow-hidden
              shadow
              ring-1 ring-black ring-opacity-5
              md:rounded-lg
            "
          >
            <!-- Selected row actions, only show when rows are selected. -->
            <div
              class="
                absolute
                top-0
                left-12
                flex
                h-12
                items-center
                space-x-3
                bg-gray-50
                sm:left-16
              "
            ></div>

            <table
              class="min-w-full table-fixed divide-y divide-gray-300"
              v-loading="loading"
            >
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="relative w-12 px-6 sm:w-16 sm:px-8">
                    <input
                      type="checkbox"
                      class="
                        absolute
                        left-4
                        top-1/2
                        -mt-2
                        h-4
                        w-4
                        rounded
                        border-gray-300
                        text-indigo-600
                        focus:ring-indigo-500
                        sm:left-6
                      "
                    />
                  </th>
                  <!--                  <th-->
                  <!--                    scope="col"-->
                  <!--                    class="-->
                  <!--                      min-w-[12rem]-->
                  <!--                      py-3.5-->
                  <!--                      pr-3-->
                  <!--                      text-left text-sm-->
                  <!--                      font-semibold-->
                  <!--                      text-gray-900-->
                  <!--                    "-->
                  <!--                  >-->
                  <!--                    姓名-->
                  <!--                  </th>-->
                  <th
                    scope="col"
                    class="
                      min-w-[12rem]
                      py-3.5
                      pr-3
                      text-left text-sm
                      font-semibold
                      text-gray-900
                    "
                  >
                    昵称
                  </th>
                  <th
                    scope="col"
                    class="
                      px-3
                      py-3.5
                      text-left text-sm
                      font-semibold
                      text-gray-900
                    "
                  >
                    联系电话
                  </th>
                  <th
                    scope="col"
                    class="
                      px-3
                      py-3.5
                      text-left text-sm
                      font-semibold
                      text-gray-900
                    "
                  >
                    电子邮箱
                  </th>
                  <th
                    scope="col"
                    class="
                      px-3
                      py-3.5
                      text-left text-sm
                      font-semibold
                      text-gray-900
                    "
                  >
                    vip
                  </th>
                  <th
                    scope="col"
                    class="
                      px-3
                      py-3.5
                      text-left text-sm
                      font-semibold
                      text-gray-900
                    "
                  >
                    角色
                  </th>
                  <th
                    scope="col"
                    class="
                      px-3
                      py-3.5
                      text-left text-sm
                      font-semibold
                      text-gray-900
                    "
                  >
                    状态
                  </th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    操作
                    <!--                    <span class="sr-only">Edit</span>-->
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <!-- Selected: "bg-gray-50" -->
                <tr v-for="(item, index) in tableData" :key="index">
                  <td class="relative w-12 px-6 sm:w-16 sm:px-8">
                    <!-- Selected row marker, only show when row is selected. -->
                    <div
                      class="absolute inset-y-0 left-0 w-0.5 bg-indigo-600"
                    ></div>

                    <input
                      type="checkbox"
                      class="
                        absolute
                        left-4
                        top-1/2
                        -mt-2
                        h-4
                        w-4
                        rounded
                        border-gray-300
                        text-indigo-600
                        focus:ring-indigo-500
                        sm:left-6
                      "
                    />
                  </td>
                  <!-- Selected: "text-indigo-600", Not Selected: "text-gray-900" -->
                  <!--                  <td-->
                  <!--                    class="-->
                  <!--                      whitespace-nowrap-->
                  <!--                      py-4-->
                  <!--                      pr-3-->
                  <!--                      text-sm-->
                  <!--                      font-medium-->
                  <!--                      text-gray-900-->
                  <!--                    "-->
                  <!--                  >-->
                  <!--                    {{ item.username }}-->
                  <!--                  </td>-->
                  <td
                    class="
                      whitespace-nowrap
                      py-4
                      pr-3
                      text-sm
                      font-medium
                      text-gray-900
                    "
                  >
                    {{ item.nickName }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ item.mobile }}
                  </td>
                  <td
                    width="300"
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    @dblclick="onEditEmail(index)"
                    title="双击修改邮箱"
                  >
                    <div class="h-full w-full">
                      <el-input
                        v-if="editEmailIndex === index"
                        v-model="item.email"
                        @keyup.enter.native="saveEmail(item)"
                      ></el-input>
                      <span v-else>{{ item.email }}</span>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ item.vip === 1 ? "是" : "否" }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ item.role === "admin" ? "管理员" : "用户" }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <el-tag type="success" v-if="item.status === 1"
                      >正常</el-tag
                    >
                    <el-tag type="wrning" v-else>禁用</el-tag>
                  </td>
                  <td
                    class="
                      whitespace-nowrap
                      py-4
                      pl-3
                      pr-4
                      text-center text-sm
                      font-medium
                      sm:pr-6
                    "
                  >
                    <el-button
                      type="text"
                      v-if="item.status !== 1"
                      @click="handleAccountStatus(item, 1)"
                      >启用</el-button
                    >
                    <el-button
                      type="text"
                      v-else
                      style="color: #f56c6c"
                      @click="handleAccountStatus(item, 0)"
                      >禁用</el-button
                    >
                    <!--                    <a href="#" class="text-indigo-600 hover:text-indigo-900"-->
                    <!--                      >编辑<span class="sr-only">, Lindsay Walton</span></a-->
                    <!--                    >-->
                  </td>
                </tr>

                <!-- More people... -->
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="py-6">
        <el-pagination
          background
          :page-size="page.pageSize"
          :page-count="page.pageIndex"
          layout="prev, pager, next"
          :total="page.pageTotal"
          @current-change="changePage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  data() {
    return {
      loading: false,
      page: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      searchForm: {
        username: "",
        status: "",
      },
      tableData: [],

      editEmailIndex: -1, // 修改邮箱
    };
  },
  mounted() {
    // 获取用户列表
    this.getList();
  },
  /**
   * 页面调用函数
   * @type {Object}
   */
  methods: {
    /**
     *  @actions:  获取列表数据
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/24 23:51
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    getList() {
      this.loading = true;
      this.$cloud
        .get("account/list", {
          ...this.page,
          ...this.searchForm,
        })
        .then((res) => {
          this.loading = false;
          if (res) {
            this.tableData = res.list;
            this.page.pageTotal = res.total;
          } else {
            this.$message.error(res);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
    changePage(e) {
      console.log("e", e);
      this.page.pageIndex = e;
      this.getList();
    },

    /**
     *  @actions:  用户状态操作
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/25 23:51
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleAccountStatus(data, status) {
      let massage = "确定启用该禁用的用户吗？";
      if (status === 2) {
        massage = "确定禁用该正常用户吗？";
      }
      this.$confirm(massage, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.$cloud
          .post("account/edit_status", {
            id: data.id,
            status,
          })
          .then(() => {
            this.loading = false;
            this.$message.success("操作成功！");
            this.getList();
          })
          .catch((err) => {
            this.loading = false;
            this.$message.error(err);
          });
      });
    },

    /**
     *  @actions:  双击打开修改邮箱
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/11 9:23
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    onEditEmail(index) {
      this.editEmailIndex = index;
    },

    /**
     *  @actions:  修改邮箱
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/11 9:37
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    saveEmail(data) {
      if (data.email) {
        let reg =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        if (reg.test(data.email)) {
          this.loading = true;
          this.$cloud
            .post("account/edit_email", {
              id: data.id,
              email: data.email,
            })
            .then(() => {
              this.loading = false;
              this.$message.success("邮箱修改成功！");
              this.editEmailIndex = -1;
            })
            .catch((err) => {
              this.loading = false;
              this.$message.error(err);
            });
        } else {
          this.$message.error("邮箱不正确！");
        }
      } else {
        this.editEmailIndex = -1;
      }
    },
  },
};
</script>

<style lang="scss"></style>
